import React from "react";
import {PiSealCheckDuotone} from "react-icons/pi";
import {Button} from "@nextui-org/react";
import { lang } from "@/lang/langT";
import { useRouter } from "next/router";
import PricingCard from "@/components/PricingCard";
import userPlans from "@/libs/userPlans";



const Pricing = () => {
  const { locale } = useRouter();
  const t = lang(locale);

  return (
    <section className="bg-zinc-950 py-24">
      <div className=" px-4 mx-auto max-w-screen-xl ">
        <div className="mx-auto mb-[60px] max-w-[510px] text-center">
  <span className="mb-2 block text-4xl font-semibold text-primary">
    {t.pricingPage.pricing}
  </span>
          <h2
            className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
            {t.pricingPage.pricingPlans}
          </h2>
          <p className="text-base text-body-color dark:text-dark-6">
            {t.pricingPage.choose}
          </p>
        </div>

        <div
          className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {/* Pricing Card */}
            {Object.values(userPlans).map(userPlan => <PricingCard userPlan={userPlan} />)}
          {/* Pricing Card */}
        </div>
      </div>
    </section>

  )
}

export default Pricing
import Menu from "@/components/menu";
import {Button} from "@nextui-org/react";
import {RiArrowRightDoubleFill} from "react-icons/ri";
import Image from "next/image";
import {useRouter} from "next/router";
import {lang} from "@/lang/langT";
import Link from "next/link";
import {BiBitcoin} from "react-icons/bi";
import {BsFillBookmarkCheckFill} from "react-icons/bs";
import {FiChevronRight} from "react-icons/fi";
import Stats from "components/homeSections/Stats";
import Teams from "@components/homeSections/Teams";
import PopularEducations from "@components/homeSections/PopularEducations";
import axios from "axios";
import Head from "next/head";
import Footer from "@components/footer";
import Pricing from "@/components/homeSections/Pricing";


const Home = ({data}) => {
  const {push, locale} = useRouter();
  const t = lang(locale);

  return (
    <>

      <Head>
        <title>Trader Edit</title>
        <meta name="description" content="Trader Edit"/>
        <meta name="keywords"
              content="Borsa, Finans, Yatırım, Hisse Senetleri, Kripto Para, Forex, Ticaret, Portföy Yönetimi, Piyasa Analizi, Risk Yönetimi , Stock Exchange, Finance, Investment, Stocks, Cryptocurrency, Forex, Trade, Portfolio Management, Market Analysis, Risk Management"/>
        <meta name="author" content="Trader Edit"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="theme-color" content="#000000"/>

        <link rel="icon" href="/favicon.ico"/>
      </Head>


      {/* <Menu/> */}

      <section className={" pt-20 pb-0 sm:py-16 min-h-screen"}>
        <div className="xl:container">
          <div
            className=" pb-0 pt-20 sm:py-20 lg:py-0 flex flex-col lg:flex-row gap-6 ">
            <div className={" lg:py-40 flex flex-col gap-4 w-full" +
              " lg:w-1/2"}>
              {
                locale === "tr" ? (
                  <h2 className={"mb-4 px-4"}>
                    <div
                      className=" text-center lg:text-start font-extrabold text-2xl sm:text-4xl xl:text-5xl">
                      <span className="text-yellow-600">Trader Edit</span> '
                      e {' '}
                      Hoşgeldiniz...
                    </div>
                  </h2>
                ) : (
                  <h2 className={"mb-4 px-4"}>
                    <div
                      className=" text-center lg:text-start font-extrabold  text-2xl sm:text-4xl xl:text-5xl">
                      Welcome to {' '}
                      <span className="text-yellow-600">Trader Edit...</span>
                    </div>
                  </h2>
                )
              }


              <p className={" px-4 text-sm sm:text-lg mb-8 text-center" +
                " lg:text-start" +
                " text-zinc-300"}>
                {/* {t.inProgressUnderConstructuor} */}
              </p>

              <div className="flex gap-4 justify-center lg:justify-start px-4">
                <Button color={"warning"} variant={"bordered"}
                        className={" text-base sm:text-xl rounded pr-8 py-7"}
                        onClick={() => {
                          push("/auth/register")
                        }}
                >
                  <RiArrowRightDoubleFill fontSize={26}/>
                  <span> {t.getStarted}</span>

                </Button>
              </div>
            </div>
            <div className={"flex justify-center items-center relative" +
              " w-full min-h-[400px] sm:min-h-[600px]" +
              " lg:w-1/2"}>
              <Image src={"/Home.png"} alt={"animation"} fill
                     className={" object-contain"} quality={50}
                     priority={true}/>
            </div>
          </div>
        </div>
      </section>

      {/* <Stats/> */}

      <section className={"bg-black py-24 "}>
        <div className="xl:container px-6">
          <div className="flex flex-col lg:flex-row items-start gap-16">
            <div
              className=" w-full lg:w-4/12 relative max-w-lg mx-auto min-h-[60vw] sm:min-h-[420px] object-cover">
              <Image src={"/homImage1.jpg"} alt={"image"} fill
                     className={"object-cover rounded-3xl"}/>
            </div>
            <div
              className=" w-full lg:w-8/12 flex flex-col justify-center">
              <h3 className={"font-bold text-2xl md:text-4xl mb-4 flex" +
                " items-center"}>
                <BiBitcoin
                  className={"inline-block mr-1 text-yellow-500 animate-bounce"}
                  fontSize={48}/>
                {locale === 'tr' ? 'Borsa ve kripto paralar' : 'All financial markets'}
              </h3>
              <p
                className={"text-sm sm:text-base leading-5 sm:leading-6 font-light" +
                  " text-zinc-300" +
                  " mb-8"}>
                {locale === 'tr' ? 'Tüm Finansal Piyasalarda Her Tür Yatırımcı - Trader ve Kurumsal Yapı için Analiz, Araştırma, Eğitim ve Destek Hizmetleri':'Analysis, Research, Training and Support Services for All Types of Investors - Traders and Corporate Structures in All Financial Markets'}
              </p>

              <ul className={"flex flex-col sm:flex-row gap-6 mb-12"}>

                <div className={"space-y-4 sm:space-y-8 "}>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#F59E0B"} fontSize={24}/>
                    {locale === 'tr' ? 'Teknik Analiz' : 'Technical Analysis'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#F59E0B"} fontSize={24}/>
                    {locale === 'tr' ? 'Makro Analiz' : 'Macro Analysis'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#F59E0B"} fontSize={24}/>
                    {locale === 'tr' ? 'Yapay Zekalı Haber Botları' : 'News bots with AI'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#F59E0B"} fontSize={24}/>
                    {locale === 'tr' ? 'Eğitim Setleri' : 'Education Sets'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#F59E0B"} fontSize={24}/>
                    {locale === 'tr' ? 'Kriptopara Özel Satışları' : 'Private Sells'}
                  </li>
                </div>

                <div className={"space-y-4 sm:space-y-8"}>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'ABD - Avrupa - Asya Borsaları' : 'ABD - Avrupa - Asia Markets'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'Forex Piyasaları' : 'Forex Markets'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'Kripto Piyasaları' : 'Crypto Markets'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'Değerli Metaller - Sanayi Metalleri' : 'Precious Metals - Industrial Metals'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'Enerji Piyasaları' : 'Energy Markets'}
                  </li>
                  <li className={"flex gap-2 text-xs sm:text-base"}>
                    <BsFillBookmarkCheckFill color={"#3BA605"} fontSize={24}/>
                    {locale === 'tr' ? 'Emtialar' : 'Commodities'}
                  </li>
                </div>
              </ul>

              {/* <Link href="/analysis/blockchain/bitcoin"
                    className={"flex text-sm sm:text-base items-center" +
                      " text-blue-600 duration-300" +
                      " hover:text-blue-500"}>

                {t.clickMoreDetails}
                <FiChevronRight fontSize={20} className={"inline-block ml-1"}/>
              </Link> */}

            </div>
          </div>
        </div>
      </section>

      {/* <PopularEducations data={data}/> */}

      <Pricing />

      <Teams/>

      <Footer/>


    </>
  );
};

export default Home;


export async function getServerSideProps(context) {

  const {data} = await axios.get(`${process.env.APP_URL}/api/popularEducation`);

  return {
    props: {
      data: data.data
    }
  }
}


